@media screen and (max-width: 1140px) {
    .achievement--heading {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.achievement--heading {
    text-align: left;
}

.link {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
}
