.container--grey {
    background-color: #E2DED3;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.short--title {
    font-size: 21px;
    font-weight: 300;
    line-height: 1.4;
}

.education--description {
    max-width: 1100px;
    line-height: 1.4;
    margin-bottom: 32px;
    padding-top: 48px;
}

.image {
    height: auto;
    max-width: 246px;
    border-radius: 250px;
    transform: scale(0.9);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);

}

.item-details {
    text-align: left;
    line-height: 1;
}

p {
    font-size: 15px;
    font-weight: 300;
}
