.header {
    position: sticky;
    top: 0;
    min-height: 50px;

    justify-content: center;
    align-items: center;
    gap: 50px;
    z-index: 10;

    background-color: #E2DED3;
}

.header--item:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.header--item {
    cursor: pointer;
}
