@media screen and (max-width: 1200px) {
    .container--experience{ padding-left: 40px;}
}

.container--experience {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abilities--grid {
    max-width: 1100px;
    line-height: 1.4;
    margin-bottom: 32px;
    padding-top: 48px;
}

li {
    display: flex;
    text-align: left;
    justify-content: space-between;
}

ul {
    padding: 0;
}

.skills--column {
    margin: 0;
}

.first--row {
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 12px;
    padding-right: 50px;
    font-weight: 400;
}
